<template>
  <v-container>
    <v-row v-if="pageIsLoading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-show="pageIsError"
      transition="fade-transition"
      style="z-index: 1; position: fixed; right: 15px; min-width: 300px"
      color="error"
      type="error"
    >
      An error occurred!
    </v-alert>

    <v-row v-if="!pageIsLoading">
      <v-col class="d-flex align-center">
        <v-icon
          class="grey--text text-h4 mr-5"
          title="Go back to kanban stage"
          @click="
            $router.push({
              path: `/dashboard/admin/kanban/`,
            })
          "
        >
          mdi-arrow-left-circle
        </v-icon>

        <label class="text-h5">
          <span> {{stg_itm.kbn_stg.stage_name}}/ Objective List/ </span> 
          <span v-if="kanban_type === 'Deal'" class="grey--text"> ( {{stg_itm.deal.public_name}} ) </span> 
          <span v-if="kanban_type === 'Lender on boarding'" class="grey--text"> ( {{stg_itm.company.name}} ) </span> 
        </label>
      </v-col>
    </v-row>

    <v-row v-if="!pageIsLoading">
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel class="bordered">
            <v-expansion-panel-header> 
              <div>
                <v-icon>mdi-bullseye-arrow</v-icon> Objectives
              </div> 
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers" :items="stg_objs" :search="search" :sort-by="sortBy" :sort-desc="sortDesc"
                @click:row="viewObjective" @update:sort-by="updateSortBy" @update:sort-desc="updateSortDesc" 
              >
                <template #item.createdAt="{ item }">
                  {{ formatDate(item.createdAt) }}
                </template>

                <template #item.updatedAt="{ item }">
                  {{ formatDate(item.updatedAt) }}
                </template>
              </v-data-table>
              
              <v-btn class="mt-5" @click="$router.push({ path: `/dashboard/admin/kanban/${kanban_type}/stage-item/${stage_item_id}/objective/add` })">
                create objective
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="bordered">
            <v-expansion-panel-header>
              <div>
                <v-icon>mdi-archive-outline</v-icon> Archived Objectives
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers" :items="archived_objectives" :search="search" :sort-by="sortBy" :sort-desc="sortDesc"
                @click:row="viewObjective"  @update:sort-by="updateSortBy" @update:sort-desc="updateSortDesc"
              >
                <template #item.createdAt="{ item }">
                  {{ formatDate(item.createdAt) }}
                </template>

                <template #item.updatedAt="{ item }">
                  {{ formatDate(item.updatedAt) }}
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from '@/plugins/API';

export default {

  props: ['kanban_type','stage_item_id'],

  data: () => ({
    panel: [0 , 1],
    pageIsLoading: true,
    pageIsError: false,

    // headers for data table
    headers: [
        { text: "No. Of Days", value: "count" },
        { text: "Title", value: "title" },
        { text: "Description", value: "description" },
        { text: "Status", value: "status" },
        { text: "Day Limit", value: "day_limit" },
        { text: "Tasks", value: "task_count" },
    ],
    search: "",
    sortBy: "id",
    sortDesc: false,

    stg_itm: {}
  }),

  computed: {
    archived_objectives() {
      const filtered_objectives = this.stg_itm.stg_objs.filter(objective => objective.status === 'Archived');
      return filtered_objectives;
    },

    stg_objs() {
      const filtered_objectives = this.stg_itm.stg_objs.filter(objective => 
        objective.status === 'To Do' || objective.status === 'On Progress' || objective.status === 'Done')
      return filtered_objectives
    },
  },

  methods: {
    viewObjective(value){
      const objective_okay_to_open = this.stg_itm.stg_objs.some(objective => {
        if(objective.order === value.predecessor) {
          return objective.status === 'Done'
        }

        if(value.predecessor === 0) {
          return true;
        }
      });

      const predecessor = this.stg_itm.stg_objs.find(objective => objective.order === value.predecessor);

      if (!objective_okay_to_open) {
        alert(`Unable to enter this objective because the status of the objective titled: ${predecessor.title} is not yet Done.`)
      }
      if (objective_okay_to_open) {
        this.$router.push({ path: `/dashboard/admin/kanban/${this.kanban_type}/stage-item/${this.stage_item_id}/objective/read/${value.id}` });
      }
    },

    updateSortBy(newSortBy) {
      this.sortBy = newSortBy;
    },
    updateSortDesc(newSortDesc) {
      this.sortDesc = newSortDesc;
    },
    
    getDays(created_date) {
      // strapi date format to mm/dd/yyyy
      const dateFormatted = this.formatDate(created_date);

      // specify the start date in mm/dd/yyyy format
      const startDate = new Date(dateFormatted);

      // get the current system date
      const endDate = new Date();

      // calculate the time difference between the two dates
      const timeDiff = endDate.getTime() - startDate.getTime();

      // convert the time difference to days
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      return daysDiff;
    },

    formatDate(date_value) {
      // create a new Date object from the string
      const dateObj = new Date(date_value);

      // extract the date components from the date object
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // add 1 since month is zero-based
      const day = dateObj.getDate();

      // format the date components as mm/dd/yyyy
      const formattedDate = `${month}/${day}/${year}`;

      return formattedDate;
    },

    async readStageItem() {
      try {
        this.pageIsLoading= true;
        
        const res = await API().get(`api/kanban/read_stage_item/?stg_itm_id=${this.stage_item_id}`);

        console.log('stage item: ', res);

        this.stg_itm = res.data;

        // add count property to stg_objs and task_count property to stg_objs
        this.stg_itm.stg_objs.forEach((objective, index) => {
          objective.count = this.getDays(objective.createdAt);
          objective.task_count = objective.stg_obj_tsks.length;
        });
        
        this.pageIsLoading= false;
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    }
  },

  mounted() {
    this.readStageItem();
  } 
};
</script>

<style lang="scss">
  .bordered{
    border: 1px solid #3c3c3c !important;
    background-color: transparent !important;
  }
</style>